import {log} from "../../utils/index"
import '../../utils/autoscale';
import "../../assets/styles/reset.css"
import "../../assets/styles/global.less"
import "./privacy_policy.less"
import "./privacy_policy.html"

import "../../../node_modules/lightbox2/dist/js/lightbox";
import "../../../node_modules/lightbox2/dist/css/lightbox.css";

$(()=>{log($("#about-title").text())});
